import React from "react";

// components

import Table from "./Table.js";

export default function ReportIndex() {
    var color = "light";//"dark";

    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12 px-4">
                    <Table color={color} />
                </div>
            </div>
        </>
    );
}
