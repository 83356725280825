import React, { useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Loading } from "../../utils/Utilities";

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [login, setLogin] = useState({ username: "", password: "" });


    const handleChange = (e) => {
        const { id, value } = e.target;
        setLogin({ ...login, [id]: value });
    }

    const makeLogin = (e) => {
        if (login?.email !== null && login?.email !== "") {
            if (login?.password !== null && login?.password !== "") {
                axiosLogin(login);
            } else {

            }
        } else {

        }
    }
    const makeLogout = (e) => {
        setToken("");
        localStorage.setItem("token", "");
        toast.success('Logout efetuado com sucesso!', { position: 'top-right' });
    }

    const toAdmin = (e) => { window.location.replace(window.location.origin + '/admin/Dashboard'); }


    const axiosLogin = (login) => {
        setLoading(true);
        axios.post(`${global.api_url}api/Authentication/Login`, login)
            .then((response) => {
                if (response.status === 200) {
                    var roles = response?.data?.roles.split(',')
                    if (roles.includes("RH")) {
                        toast.success('Login efetuado com sucesso', { position: 'top-right' });
                        localStorage.setItem("token", response.data?.token);
                        window.location.replace(window.location.origin + '/admin/Dashboard');
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error('Não tem permissões.', { position: 'top-right' });
                        localStorage.setItem("token", "");
                    }
                    setLoading(false);
                } else { setLoading(false); toast.error(response.statusText, { position: 'top-right' }); }
            }).catch(error => {
                setLoading(false);
                if (error.response?.status === 401) {
                    localStorage.setItem("token", "");
                    toast.error("Credenciais inválidas.", { position: 'top-right' });
                } else {
                    localStorage.setItem("token", "");
                    toast.error(error?.message, { position: 'top-right' });
                }
            })
    }


    return (
        <>
            {Loading(loading)}
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">

                            {(token === "" || token === null) ?
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                                        <small>Login</small>
                                    </div>
                                    <form>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Username
                                            </label>
                                            <input
                                                id="username"
                                                type="email"
                                                onChange={handleChange}
                                                value={login.email}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Email"
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                id="password"
                                                type="password"
                                                onChange={handleChange}
                                                value={login.password}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                            />
                                        </div>

                                        <div className="text-center mt-6">
                                            <button
                                                disabled={loading}
                                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={makeLogin}
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                : <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                                        <small>Com login efetuado</small>
                                    </div>
                                    <form>

                                        <div className="text-center mt-6">
                                            <button
                                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={toAdmin}
                                            >
                                                Administração
                                            </button>
                                        </div>

                                        <div className="text-center mt-6">
                                            <button
                                                disabled={loading}
                                                className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={makeLogout}
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    </form>
                                </div>}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
