import React, { useRef, useState } from "react";
import axios from 'axios';
import toast from 'react-hot-toast';
import DetailReport from "./detailReport.js";
import ReCAPTCHA from "react-google-recaptcha";
import { GetSiteKeyReCAPTCHA } from "../../../utils/Utilities.js";

export default function ConsultReport() {
    var cleanReport = { hashKey: "" }
    const [allReport, setAllReport] = useState(null);
    const [hashKey, setHashKey] = useState(cleanReport);
    const captchaRef = useRef(null);
    const grecaptchaObject = window.grecaptcha;



    const CheckCaptcha = (hashKeyValue) => {
        const token = captchaRef?.current?.getValue();
        if (token !== "" && token !== null && token !== undefined) {
            GetReport(hashKeyValue);
        } else {
            toast.error("ReCaptcha precisa ser verificada.", { position: "top-right" })
        }
    }


    const GetReport = (hashKeyValue) => {
        if (hashKeyValue !== "" && hashKeyValue !== null) {
            axios.get(global.api_url + "Report/GetReport/" + hashKeyValue)
                .then((response) => {
                    if (response.status === 200) {
                        if (response?.data?.report?.id !== 0) {
                            setAllReport(response?.data);
                            toast.success('Denuncia obtida com sucesso!', { position: 'top-right' });
                        } else {
                            setAllReport(null);
                            toast.error('Código inválido!', { position: 'top-right' });
                        }
                    }
                }).catch(error => {
                    toast.error("Código inválido!", { position: 'top-right' });
                })
        } else {
            toast.error('Código inválido!.', { position: 'top-right' });
        }

    }

    const handleChangeHash = (event) => {
        const { value } = event.target;
        setHashKey({ ...hashKey, "hashKey": value });

    }

    const closeReport = () => {
        setHashKey({ ...hashKey, "hashKey": "" });
        setAllReport(null);
    }

    const loadReport = (hashKeyValue) => {
        setAllReport(null);
        GetReport(hashKeyValue);
    }



    return (
        <>
            {allReport !== null ?
                <DetailReport allReportAux={allReport} closeReport={closeReport} loadReport={loadReport}></DetailReport>
                :
                <section className="header relative pt-32 pb-32 items-center flex">
                    <div className="container mx-auto items-center flex flex-wrap">
                        <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 px-4">

                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-1">
                                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <form>
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Consultar denuncia
                                        </h6>

                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Código de acesso á denuncia:
                                                    </label>
                                                    <input
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        id="haskKey"
                                                        name="haskKey"
                                                        value={hashKey?.hashKey}
                                                        onChange={handleChangeHash}
                                                    />

                                                </div>
                                            </div>
                                        </div>


                                        <div className="w-full lg:w-12/12 px-4">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <ReCAPTCHA
                                                    sitekey={GetSiteKeyReCAPTCHA()}
                                                    ref={captchaRef}
                                                    grecaptcha={grecaptchaObject}
                                                />
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="w-full lg:w-12/12 px-4">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <button
                                                    className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={(e) => { e.preventDefault(); CheckCaptcha(hashKey?.hashKey); }}>
                                                    Obter denuncia
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}
